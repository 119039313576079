import React, { useCallback, useState, useEffect } from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useAuth, AuthProvider } from 'oidc-react';
import { useLocation, Navigate, Outlet, useSearchParams } from 'react-router-dom';

import Home from './pages/Home';
import SiteMenu from './SiteMenu';
import Page from './Page';

import { OIDC_CONFIG } from '@src/config';


const theme = createTheme({
  typography: {
    fontFamily: 'Marianne, Arial, sans-serif',
    h1: {
      color: '#000091',
      fontSize: '2.5em',
    },
    h2: {
      fontSize: '1.5em',
    },
  },
  palette: {
    primary: {
      main: '#000091',
      light: '#5231c3',
      dark: '#000062',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#c9191e',
      light: '#ff5747',
      dark: '#900000',
      contrastText: '#ffffff',
    },
  },
});


const ANONYMOUS_PAGES = [
  '/'
];

const AUTH_QUERY_PARAMS = [
  'session_state',
  'state',
  'code',
];

function LoadingPage() {
  return (
    <Page>
      <Typography>
        Loading...
      </Typography>
    </Page>
  );
}


function CheckAuthenticated({ children }) {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const allowAnonymous = ANONYMOUS_PAGES.includes(pathname);
  const { userData, isLoading, signIn } = useAuth();
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  const blocked = !(loading || allowAnonymous || userData);
  useEffect(() => {
    if (blocked) {
      signIn();
    }
  }, [blocked]);
  return (
    <>
      {blocked ? null : (
        loading ? <LoadingPage/> : children
      )}
    </>
  );
}


function useCleanQueryParams(params) {
  const [searchParams, setSearchParams] = useSearchParams();

  return useCallback(() => {
    params.forEach((p) => searchParams.delete(p));
    setSearchParams(searchParams);
  }, [params]);
}


export default function Root() {
  
  const { pathname } = useLocation();
  const onSignIn = useCleanQueryParams(AUTH_QUERY_PARAMS);
  const redirectUri =OIDC_CONFIG.redirectUri + pathname;

  return (
    <AuthProvider
      {...OIDC_CONFIG}
      postLogoutRedirectUri={OIDC_CONFIG.redirectUri}
      redirectUri={OIDC_CONFIG.redirectUri + pathname}
      autoSignIn={false}
      onSignIn={onSignIn}
      automaticSilentRenew={true}
    >
      <ThemeProvider theme={theme}>
        <SiteMenu/>
        <CheckAuthenticated>
          <Outlet/>
        </CheckAuthenticated>
      </ThemeProvider>
    </AuthProvider>
  );
}
