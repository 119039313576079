import React from 'react';

import Typography from '@mui/material/Typography';

import Page from '@src/components/Page';
import Geocoder from '@src/components/Geocoder';


export default function Page2() {
  return (
    <Page
      title={
        <Typography variant="h1">
          Éditer
        </Typography>
      }
      intro={
        <Typography>
          Accéder à l'interface d'édition à
          partir d'une ville ou d'une adresse.
        </Typography>
      }
    >
      <Geocoder/>
    </Page>
  );
}
