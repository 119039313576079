export const NETEX = 'NETEX';
export const OSM = 'OSM';

export const LABELS = {
  NETEX: 'NeTEx',
  OSM: 'OpenStreetMap',
};

export const ACCEPTS = {
  NETEX: 'text/xml',
  OSM: '.osm,text/xml',
};

