import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Page from '@src/components/Page';
import dgitm_logo from '@assets/img/dgitm_logo.png';
import france_relance_logo from '@assets/img/france_relance_logo.png';
import nextgeneu_logo from '@assets/img/nextgeneu_logo.png';
import MAMP_logo from '@assets/img/mamp-logo.png';
import ECLA_logo from '@assets/img/ecla-logo.png';
import Evian_logo from '@assets/img/evian-logo.png';
import Someware_logo from '@assets/img/someware-logo.png';
import JungleBus_logo from '@assets/img/junglebus-logo.png';
import AdrienPavie_logo from '@assets/img/adrienpavie-logo.png';
import Atipy_logo from '@assets/img/atipy-logo.png';
import * as styles from './Home.module.css';


function ItemLogo({ url, img, alt, ...gridProps }) {
  const ImageComponent = () => <img src={img} alt={alt} style={{ maxWidth: '180px', margin: 'auto' }} />;
  const LinkComponent = ({ children }) => (
    <a href={url} target="_blank" style={{ margin: 'auto' }}>
      {children}
    </a>
  );
  return (
    <Grid item sx={{ display: 'flex' }} {...gridProps }>
      {url ? (
        <LinkComponent>
          <ImageComponent />
        </LinkComponent>
      ) : <ImageComponent/>}
    </Grid>
  );
}


export default function Home() {
  return (
    <Page
      title={<Typography variant="h1">Accueil</Typography>}
    >
      <div className={styles.textContent}>
        <Typography variant="h2">
          Des outils pour cartographier l'accessibilité Voirie / Transports / ERP.
        </Typography>
        <Typography paragraph>
          Acceslibre Mobilités est une suite d'outils open source de relevé de terrain
          des données d'accessibilité dans les transports et la voirie alentours,
          respectant le standard CNIG - Accessibilité et le format d'échange NeTEx.
        </Typography>
        <Typography variant="h2">
          Le projet
        </Typography>
        <Typography paragraph>
          Le développement est piloté par la <strong> Direction générale des infrastructures,
          des transports et des mobilités (DGITM)</strong>, avec les cofinancements de <strong> France
          Relance</strong> et du programme <strong>Next Gen EU</strong> de l'Union Européenne.
        </Typography>
        <Grid container spacing={2}>
          <ItemLogo
            sm={4} xs={12}
            url="https://www.ecologie.gouv.fr/direction-generale-des-infrastructures-des-transports-et-des-mobilites-dgitm"
            img={dgitm_logo}
            alt="logo DGITM"
          />
          <ItemLogo sm={4} xs={12} img={france_relance_logo} alt="logo France Relance"/>
          <ItemLogo sm={4} xs={12} img={nextgeneu_logo} alt="logo NetxtGen EU"/>
        </Grid>
        <Typography paragraph>
          Dans le cadre d'une passation de marché public, le développement et la réalisation ont
          été portées par les sociétés suivantes :
        </Typography>
        <Grid container spacing={2}>
          <ItemLogo md={3} sm={6} xs={12} url="https://www.someware.fr" img={Someware_logo} alt="Someware Handimap La vilel accessible à tous"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://junglebus.io" img={JungleBus_logo} alt="Jungle Bus"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://pavie.info" img={AdrienPavie_logo} alt="Adrien Pavie"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://www.atipy.fr" img={Atipy_logo} alt="Atipy conception universelle et +"/>
        </Grid>
      </div>
    </Page>
  );
}
