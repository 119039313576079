
.heading {
  background: #EEE;
}

.title {
  max-width: 960px;
  margin: auto;
  padding: 20px;
}

.body {
  max-width: 960px;
  margin: auto;
  padding: 20px;
}

.intro {
  font-style: italic;
  padding-bottom: 20px;
}
