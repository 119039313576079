import { useState, useEffect } from 'react';
import { useAuth } from 'oidc-react';

import useApiClient from './useApiClient';


export default function useCallApi(endpoint, opts={}) {
  const [ result, setResult ] = useState();
  const api = useApiClient();
  useEffect(() => {
    if (api.current) {
      const callApi = async () => {
        const resp = await api.current.fetch(endpoint, opts);
        const res = await resp.json();
        setResult(res);
      }
      callApi();
    }
  }, [api.current, endpoint]);

  return result;
}

