import React from 'react';

import { ONLINE_DOCS_ROOT } from '@src/config.js';


export default function DocLink({ path, children }) {
  const url = ONLINE_DOCS_ROOT + path;
  return (
    <a href={url} target="_blank">
      { children || url }
    </a>
  );
}
