
export const KNOWN_LICENSES = {
  'odbl': {
    name: 'ODbL',
    url: 'https://opendatacommons.org/licenses/odbl/',
    allow_sharing: true,
    allow_modifications: true,
    require_share_alike: true,
    require_attribution: true,
  },
  'lo': {
    name: "Licence ouverte",
    url: 'https://www.etalab.gouv.fr/licence-ouverte-open-licence/',
    allow_sharing: true,
    allow_modifications: true,
    require_share_alike: false,
    require_attribution: true,
  },
};



