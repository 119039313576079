import React from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import EditIcon from '@mui/icons-material/Edit';

import useCallApi from '@src/hooks/useCallApi';
import Page from '@src/components/Page';


export default function Account() {
  const user = useCallApi('/user');
  return (
    <Page
      title={
        <Typography variant="h1">
          Mon compte
        </Typography>
      }
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <PersonIcon/>
          </ListItemIcon>
          <ListItemText>
            {user?.name || ''}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon/>
          </ListItemIcon>
          <ListItemText>
            {user?.email || ''}
          </ListItemText>
        </ListItem>
        {user?.auth_provider ? (
          <ListItem>
            <ListItemButton component="a" href={user.auth_provider + '/account'} disableGutters>
              <ListItemIcon>
                <EditIcon/>
              </ListItemIcon>
              <ListItemText>
                Gérer mon compte
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
    </Page>
  );
}
