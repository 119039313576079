import React from 'react';
import { Outlet } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Page from '@src/components/Page';
import ConfigWizard from '@src/components/pages/ConfigWizard';


export default function Configure() {
  return (
    <Page
      title={
        <Typography variant="h1">
          Configurer
        </Typography>
      }
    >
      <Outlet/>
    </Page>
  );
}

