import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LaunchIcon from '@mui/icons-material/Launch';

import { EDITOR_URL } from '@src/config';
import useDebounce from '@src/hooks/useDebounce';


async function geocode(text) {
  const url=`https://api-adresse.data.gouv.fr/search/?q=${text}`;
  const response = await fetch(url);
  return response.json();
}


function displayFeature({ properties={} }) {
  const { type, label, context } = properties;
  const displayContext = type && type === 'municipality';
  if (displayContext) {
    return `${label} (${context})`;
  }
  return label;
}


export default function Geocoder() {
  const [text, setText] = useState('');
  const debounced = useDebounce(text, 500);
  const [suggestions, setSuggestions] = useState([]);
  const onChange = (e) => {
    setText(e.target.value);
  }

  useEffect(() => {
    const execute = async () => {
      const results = await geocode(debounced);
      setSuggestions(results.features);
    };
    if (debounced.length >= 3) {
      execute().catch(console.error);
    } else {
      setSuggestions([]);
    }
  }, [debounced]);

  return (
    <>
    <TextField
      sx={{ width: '100%' }}
      label="Chercher une adresse, une ville..."
      variant="outlined"
      onChange={onChange}
      value={text}
    />
      <List>
        {suggestions.map((feature) => (
          <ListItem key={feature.properties.id}>
            <ListItemButton component="a" href={`${EDITOR_URL}#map=18/${feature.geometry.coordinates[1]}/${feature.geometry.coordinates[0]}`}>
              <ListItemIcon>
                <LaunchIcon/>
              </ListItemIcon>
              <ListItemText>
                {displayFeature(feature)}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
