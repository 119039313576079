import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import { KNOWN_LICENSES } from '@src/constants';
import DocLink from '@src/components/forms/DocLink';


const OSM_ATTRIBUTION = "Contient des données OpenStreetMap.\n";


export default function FinalLicense({
  wizard={},
  updateWizard,
  isComplete,
  setIsComplete=false,
  setError,
}) {

  const { transportStops={}, poi={}, paths={}, license={}, attribution='' } = wizard;
  const hasOsm = [transportStops, poi, paths].some(({ osm }) => !!osm);
  console.log(hasOsm);
  const { uploads=[] } = transportStops;

  const uploadsLicenses = uploads.map(({ license }) => license || {});

  const flagComplete = () => setIsComplete(!!(license.name && license.url));

  const onAttributionChange = ({ target }) => {
    updateWizard({
      ...wizard,
      attribution: target.value,
    });
  };
  
  const constraints = {
    allow_sharing: {
      forbidden: false,
      enforce: hasOsm,
    },
    allow_modifications: {
      forbidden: false,
      enforce: hasOsm,
    },
    require_attribution: {
      forbidden: false,
      enforce: hasOsm || uploadsLicenses.some(({ require_attribution }) => require_attribution),
    },
    require_share_alike: {
      forbidden: false,
      enforce: hasOsm || uploadsLicenses.some(({ require_share_alike }) => require_share_alike),
    },
  };


  const allowed = Object.keys(KNOWN_LICENSES).filter((key) => {
    if (hasOsm) {
      return key == 'odbl';
    }
    return true;
  });

  const disableCheck = (name) => {
    return (
      !!license.key || (constraints[name].forbidden || constraints[name].enforce)
    );
  }

  const isChecked = (name) => {
    if (!license.key) {
      if (constraints[name].forbidden) {
        return false;
      }
      if (constraints[name].enforce) {
        return true;
      }
    }
    return !!license[name];
  }

  const firstAllowedKey = allowed.length ? allowed[0] : undefined;
  useEffect(() => {
    if (firstAllowedKey) {
      updateWizard({
        ...wizard,
        attribution: hasOsm && !attribution ? OSM_ATTRIBUTION : attribution,
        license: {
          key: firstAllowedKey,
          ...(KNOWN_LICENSES[firstAllowedKey] || {}),
        },
      });
    } else {
      const derivedLicense = {
        allow_sharing: isChecked('allow_sharing'),
        allow_modifications: isChecked('allow_modifications'),
        require_share_alike: isChecked('require_share_alike'),
        require_attribution: isChecked('require_attribution'),
      };
      updateWizard({
        ...wizard,
        attribution: hasOsm && !attribution ? OSM_ATTRIBUTION : attribution,
        license: derivedLicense,
      });
    }
    flagComplete();
  }, []);

  
  const onKnownLicenseChange = (event, value) => {
    const known = KNOWN_LICENSES[value] ?? {
      allow_sharing: isChecked('allow_sharing'),
      allow_modifications: isChecked('allow_modifications'),
      require_share_alike: isChecked('require_share_alike'),
      require_attribution: isChecked('require_attribution'),
    };
    updateWizard({
      ...wizard,
      license: {
        ...license,
        ...known,
        key: value,
      },
    });
    flagComplete();
  };

  const onLicenseNameChange = ({ target }) => {
    updateWizard({
      ...wizard,
      license: {
        ...license,
        name: target.value,
      },
    });
    flagComplete();
  };

  const onLicenseUrlChange = ({ target }) => {
    updateWizard({
      ...wizard,
      license: {
        ...license,
        url: target.value,
      },
    });
    flagComplete();
  };

  const onLicenseCheckChanges = (name) => ({ target }) => {
    updateWizard({
      ...wizard,
      license: {
        ...license,
        [name]: target.checked,
      },
    });
    flagComplete();
  }

  
  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        La licence détermine les conditions d'utilisations des données
        que vous publierez après utilisation d'AccesLibre Mobilités.
      </Typography>
      <Typography>
        <DocLink path="/usage/publish/licence/">Voir la documentation en ligne</DocLink>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, flexWrap: 'wrap'}}>
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={license.key || ''}
          onChange={onKnownLicenseChange}
          sx={{ justifyContent: 'flex-start' }}
        >
          {Object.keys(KNOWN_LICENSES).map((key) => (
            <ToggleButton key={key} disabled={!allowed.includes(key)} value={key}>
              {KNOWN_LICENSES[key].name}
            </ToggleButton>
          ))}
          <ToggleButton value="">Autre</ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl sx={{ width: '25ch', m: 1 }}>
            <TextField disabled={!!license.key} label="Nom" value={license.name || ''} onChange={onLicenseNameChange} variant="standard" />
          </FormControl>
          <FormControl sx={{ width: '100%', m: 1 }}>
            <TextField disabled={!!license.key} label="Lien" value={license.url || ''} onChange={onLicenseUrlChange} variant="standard" />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormGroup>
            <FormControlLabel disabled={disableCheck('allow_sharing')} control={<Switch checked={!!license.allow_sharing} onChange={onLicenseCheckChanges('allow_sharing')} />} label="Partage autorisé" />
            <FormControlLabel disabled={disableCheck('allow_modifications')} control={<Switch checked={!!license.allow_modifications} onChange={onLicenseCheckChanges('allow_modifications')} />} label="Modifications autorisées" />
            <FormControlLabel disabled={disableCheck('require_attribution')} control={<Switch checked={!!license.require_attribution} onChange={onLicenseCheckChanges('require_attribution')} />} label="Attribution" />
            <FormControlLabel disabled={disableCheck('require_share_alike')} control={<Switch checked={!!license.require_share_alike} onChange={onLicenseCheckChanges('require_share_alike')} />} label="Partage dans les mêmes conditions" />
          </FormGroup>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, flexWrap: 'wrap'}}>
        <Typography>
          Attributions
        </Typography>
        <textarea
          placeholder=""
          rows={5}
          value={attribution}
          onChange={onAttributionChange}
        />
      </Box>
    </Box>
  );
}
