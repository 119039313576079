import React from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import DocLink from '@src/components/forms/DocLink';


export default function ConfigHome() {
  return (
    <Box sx={{ m: 4}}>
      <Typography paragraph>
        La configuration de l'instance consite à définir l'emprise et les zones de travail prioritaires, ainsi
        que d'importer des données initiales.
      </Typography>
      <Alert severity="warning">
        <Typography>
          Attention : réaliser des imports de données alors que des données existent déjà
          sur l'instance peut mener à des doublons.
        </Typography>
        <Typography>
          <DocLink path="/usage/imports/">
            Voir la documentation  en ligne.
          </DocLink>
        </Typography>
      </Alert>
      <Box sx={{ p: 2 }}>
        <Typography>
          <Button variant="contained" component={Link} to="wizard">
            Démarrer la configuration
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}
