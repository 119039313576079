import { useRef, useEffect } from 'react';

import { useAuth } from 'oidc-react';
import ApiClient from '@src/api';

export default function useApiClient() {
  const client = useRef();
  const { userManager } = useAuth();
  useEffect(() => {
    if (userManager) {
      client.current = new ApiClient(userManager);
    }
    return () => client.current == null;
  }, [userManager]);
  return client;
}

