import React, { useRef, useState } from 'react';

import { useAuth } from 'oidc-react';

import { Link, NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';

import { HIDE_CONFIG } from '@src/config';
import ALM_logo from '@assets/img/alm_logo.svg';


const SHOW_CONGIG_MENU = !HIDE_CONFIG;


function NavDivider() {
  return (
    <div style={{ marginTop: '24px', marginBottom: '24px' }}>
      <Divider sx={{ height: '100%', width: '100%', borderColor: 'rgba(0, 0, 145, 0.4)' }} orientation="vertical" flexItem />
    </div>
  );
}


function NavMenu() {
  return (
    <>
      {SHOW_CONGIG_MENU ? (
        <>
          <Button
            sx={{ my: 2, color: 'primary', display: 'block' }}
            component={NavLink}
            to="configure"
          >
            Configurer
          </Button>
          <NavDivider/>
        </>
      ) : null}
      <Button
        sx={{ my: 2, color: 'primary', display: 'block' }}
        component={NavLink}
        to="edit"
      >
        Éditer
      </Button>
      <NavDivider/>
      <Button
        sx={{ my: 2, color: 'primary', display: 'block' }}
        component={NavLink}
        to="working-zones"
      >
        Zones de travail
      </Button>
      <NavDivider/>
      <Button
        sx={{ my: 2, color: 'primary', display: 'block' }}
        component={NavLink}
        to="export"
      >
        Exporter
      </Button>
      <NavDivider/>
      <Button
        sx={{ my: 2, color: 'primary', display: 'block' }}
        component={NavLink}
        to="app"
      >
        Application mobile
      </Button>
    </>
  );
}


function MobileMenu() {
  const anchor = useRef();
  const [ isOpen, setIsOpen ] = useState(false);
  const sx = { display: { xs: 'flex', md: 'none' } };
  return (
    <>
      <IconButton sx={sx} color="primary" ref={anchor} onClick={() => setIsOpen(true)}>
        <MenuIcon/>
      </IconButton>
      <Menu
        sx={sx}
        id="app-menu-appbar"
        anchorEl={anchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {SHOW_CONGIG_MENU ? (
          <li><MenuItem component={NavLink} to="configure">Configurer</MenuItem></li>
        ) : null}
        <li><MenuItem component={NavLink} to="edit">Éditer</MenuItem></li>
        <li><MenuItem component={NavLink} to="working-zones">Zones de travail</MenuItem></li>
        <li><MenuItem component={NavLink} to="export">Exporter</MenuItem></li>
        <li><MenuItem component={NavLink} to="app">Application mobile</MenuItem></li>
      </Menu>
    </>
  )
}


function UserMenu({ profile, signIn, signOut }) {
  const anchor = useRef();
  const [ isOpen, setIsOpen ] = useState(false);
  return (
    <>
      {profile ? (
        <>
        <Button sx={{ textTransform: 'none' }} ref={anchor} onClick={() => setIsOpen(true)}>
          {profile.name}
        </Button>
        <Menu
          id="profile-menu-appbar"
          anchorEl={anchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <li>
            <MenuItem component={NavLink} to="account">Mon compte</MenuItem>
          </li>
          <MenuItem onClick={signOut}>Déconnexion</MenuItem>
        </Menu>
        </>
      ) : (
        <Button sx={{ textTransform: 'none' }} onClick={signIn}>
          Connexion
        </Button>
      )}
    </>
  );
}



export default function SiteMenu() {
  const { isLoading, userData, signOut, signIn, signOutRedirect } = useAuth();
  const profile = userData?.profile;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="static" sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '6px 8px', color: 'primary' }}>
            <Link to="/">
              <img src={ALM_logo} alt="Propulsé par Agence de l'Innovation pour les Transports" height="60px" style={{ paddingRight: '10px' }}/>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'flex-start', color: "#primary" }}>
            {userData ? <NavMenu/> : null}
          </Box>
          <>
            {userData ? <MobileMenu/> : null}
          </>
          <UserMenu profile={profile} signIn={signIn} signOut={signOutRedirect}/>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
