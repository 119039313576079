import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

import useApiClient from '@src/hooks/useApiClient';
import { KNOWN_LICENSES } from '@src/constants';
import * as filetypes from '@src/filetypes';


async function uploadFile(api, fileType, file, description, license) {
  const formData = new FormData();
  formData.append('type', fileType);
  formData.append('fileb', file);
  formData.append('url', '');
  formData.append('source', '');
  formData.append('description', description);
  formData.append('license_name', license.name);
  formData.append('license_url', license.url);
  formData.append('license_allow_sharing', license.allow_sharing || false);
  formData.append('license_allow_modifications', license.allow_modifications || false);
  formData.append('license_require_attribution', license.require_attribution || false);
  formData.append('license_require_share_alike', license.require_share_alike || false);
  const resp = await api.fetch('/uploads/', {
    method: 'POST',
    body: formData,
  });
  if (![200, 201].includes(resp.status)) {
    throw "Error lors de du téléchargement du fichier";
  }
  const result = await resp.json();
  return result;
}


export default function FileUploadForm({
  fileType,
  file={description: ''},
  license={ key: 'odbl', ...KNOWN_LICENSES.odbl },
  error=null,
  isComplete=false,
  onChange,
  onUploaded,
}) {

  const apiClient = useApiClient();

  const cleanFileInput = ({ target }) => {
    target.value = '';
  }

  const onKnownLicenseChange = (event, value) => {
    const known = KNOWN_LICENSES[value] ?? {};
    onChange({
      file, error, isComplete, license: {
        key: value,
        ...known,
      },
    });
  }

  const onLicenseNameChange = ({ target }) => {
    onChange({
      file, error, isComplete, license: {
        ...license,
        name: target.value,
      },
    });
  }

  const onLicenseUrlChange = ({ target }) => {
    onChange({
      file, error, isComplete, license: {
        ...license,
        url: target.value,
      },
    });
  }

  const onLicenseCheckChanges = (name) => ({ target }) => {
    onChange({
      file, error, isComplete, license: {
        ...license,
        [name]: target.checked,
      },
    });
  }

  const onDescriptionChange = ({ target }) => {
    onChange({
      error, isComplete, license, file: {
        ...file,
        description: target.value,
      },
    });
  }

  const onFile = async ({ target }) => {
    const { files } = target;
    if (files.length >= 0) {
      onChange({
        file, error, license, isComplete,
      });
      try {
        const upload = await uploadFile(
          apiClient.current, 
          fileType,
          files[0],
          file.description,
          license,
        );
        onUploaded(upload);
      } catch (err) {
        onChange({
          file,
          error: err,
          license,
          isComplete,
        });
      }
    }
  }

  return (
    <>
    <FormControl sx={{ width: '25ch', m: 1 }}>
      <TextField label="Description" value={file.description || ''} onChange={onDescriptionChange} variant="standard" />
    </FormControl>
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, flexWrap: 'wrap'}}>
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={license.key || ''}
        onChange={onKnownLicenseChange}
        sx={{ justifyContent: 'flex-start' }}
      >
        {Object.keys(KNOWN_LICENSES).map((key) => (
          <ToggleButton key={key} value={key}>
            {KNOWN_LICENSES[key].name}
          </ToggleButton>
        ))}
        <ToggleButton value="">Autre</ToggleButton>
      </ToggleButtonGroup>
      {!license.key ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControl sx={{ width: '25ch', m: 1 }}>
              <TextField disabled={!!license.key} label="Nom" value={license.name || ''} onChange={onLicenseNameChange} variant="standard" />
            </FormControl>
            <FormControl sx={{ width: '100%', m: 1 }}>
              <TextField disabled={!!license.key} label="Lien" value={license.url || ''} onChange={onLicenseUrlChange} variant="standard" />
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormGroup>
              <FormControlLabel disabled={!!license.key} control={<Switch checked={!!license.allow_sharing} onChange={onLicenseCheckChanges('allow_sharing')} />} label="Partage autorisé" />
              <FormControlLabel disabled={!!license.key} control={<Switch checked={!!license.allow_modifications} onChange={onLicenseCheckChanges('allow_modifications')} />} label="Modifications autorisées" />
              <FormControlLabel disabled={!!license.key} control={<Switch checked={!!license.require_attribution} onChange={onLicenseCheckChanges('require_attribution')} />} label="Attribution" />
              <FormControlLabel disabled={!!license.key} control={<Switch checked={!!license.require_share_alike} onChange={onLicenseCheckChanges('require_share_alike')} />} label="Partage dans les mêmes conditions" />
            </FormGroup>
          </Box>
        </>
      ) : null}
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button variant="contained" component="label" color={error ? 'error' : 'primary'}>
        Choisir un fichier
        <input
          type="file"
          hidden
          accept={filetypes.ACCEPTS[fileType]}
          onChange={onFile}
          onClick={cleanFileInput}
        />
      </Button>
      {file.name ? (
        <Typography sx={{ margin: 1 }} color={error ? 'error' : 'success' }>
          { file?.name || '' } ({ file.type })
        </Typography>
      ) : null}
      {error ? (
        <Typography sx={{ margin: 1 }} color="error">
          { `${error}` }
        </Typography>
      ) : null}
    </Box>
    </>
  );
}


