import React, { useCallback, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel';

import * as filetypes from '@src/filetypes';
import { uploadDisplayName } from '@src/helpers';
import FileUploadForm from '@src/components/forms/FileUploadForm';
import DocLink from '@src/components/forms/DocLink';
import ExtentMap from '@src/components/forms/ExtentMap';


function FileTypeTab({ value, ...props }) {
  return (
    <Tab
      { ...props }
      value={value}
      label={filetypes.LABELS[value]}
      sx={{ textTransform: 'none' }}
    />
  );
}


export default function TransportStopsStep({
  wizard={},
  updateWizard,
  isComplete=false,
  setIsComplete,
  setError,
}) {

  const { transportStops={}, extent={} } = wizard;
  const { uploads=[], form={}, osm=false } = transportStops;

  const [ fileType, setFileType ] = useState(filetypes.NETEX);

  const onFileTypeChange = (event, newFileType) => setFileType(newFileType);

  const onChange = ({ file, license, error, isComplete }) => {
    updateWizard({
      ...wizard,
      transportStops: {
        ...transportStops,
        form: { file, license, error, isComplete }
      },
    });
    setError(!!error);
  };

  const onOsmChange = ({ target }) => {
    updateWizard({
      ...wizard,
      transportStops: {
        ...transportStops,
        osm: target.checked,
      },
    });
    setIsComplete(target.checked || uploads.length > 0)
  }

  const onUploaded = (upload) => {
    const newUploads = [...uploads, upload];
    updateWizard({
      ...wizard,
      transportStops: {
        ...transportStops,
        form: {},
        uploads: newUploads,
      },
    });
    setIsComplete(osm || newUploads.length > 0);
  };

  const onDelete = (index) => () => {
    const newUploads = [
      ...uploads.slice(0, index),
      ...uploads.slice(index+1, uploads.length+1),
    ]
    updateWizard({
      ...wizard,
      transportStops: {
        ...transportStops,
        uploads: newUploads,
      },
    });
    setIsComplete(osm | newUploads.length > 0);
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        L'import des arrêts de transport permet d'initialiser la base de données
        d'AccesLibre Mobilités avec des objets de transport.
      </Typography>
      <Typography>
        <DocLink path="/usage/imports/transport/">Voir la documentation en ligne</DocLink>
      </Typography>
      <Typography>
        Les sources suivantes sont supportées :
      </Typography>
      <Box sx={{ pt: 2}}>
        <Tabs value={fileType} onChange={onFileTypeChange}>
          <FileTypeTab value={filetypes.NETEX}/>
          <FileTypeTab value={filetypes.OSM}/>
        </Tabs>
        {fileType == filetypes.NETEX ? (
          <Box sx={{ pt: 2 }}>
            <Typography>
              Pour utiliser les données publiées sur le Point d'Accès National.
            </Typography>
            <Typography>
              <DocLink path="/usage/imports/transport/imports-transport-netex/">
                Voir la documentation en ligne
              </DocLink>
            </Typography>
            <FileUploadForm
              {...form }
              fileType={fileType}
              onChange={onChange}
              onUploaded={onUploaded}
            />
            <Box sx={{ pt: 2}}>
              <List>
                {uploads.map((upload, index) => (
                  <ListItem key={index} secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={onDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  }>
                    <ListItemText>
                      {`${filetypes.LABELS[upload.type]} - ${uploadDisplayName(upload)} - ${(upload.license?.name || '' )}`}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        ) : null}
        {fileType == filetypes.OSM ? (
          <Box sx={{ pt: 2 }}>
            <Typography>
              Pour utiliser les données du projet
              OpenStreetMap, le wikipédia des cartes
            </Typography>
            <Typography>
              <DocLink path="/usage/imports/transport/imports-transport-osm/">
                Voir la documentation en ligne
              </DocLink>
            </Typography>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={osm}
                  onChange={onOsmChange}
                />
              }
              label="Importer automatiquement les données de l'emprise"
              labelPlacement="end"
            />
            <ExtentMap extent={extent} defaultExpanded={true}/>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
