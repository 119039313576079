import React, { useState } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PendingIcon from '@mui/icons-material/Pending';

import WorkingZones from './steps/WorkingZones';
import TransportStops from './steps/TransportStops';
import PointsOfInterest from './steps/PointsOfInterest';
import Paths from './steps/Paths';
import FinalLicense from './steps/FinalLicense';
import Import from './steps/Import';


const STEPS = [
  { name: "Zones de travail", component: WorkingZones },
  { name: "Arrêts de transport", component: TransportStops },
  { name: "ERP", component: PointsOfInterest },
  { name: "Voirie", component: Paths },
  { name: "Licence", component: FinalLicense },
  { name: "Importer", component: Import },
];


function setArrayValue(old, index, value) {
  return [
    ...old.slice(0, index),
    value,
    ...old.slice(index+1, old.length+1),
  ];
}



export default function ConfigWizard() {
  const [ activeStep, setActiveStep ] = useState(0);

  const [ wizardState, setWizardState ] = useState({});
  const [ completeSteps, setCompleteSteps ] = useState(STEPS.map(() => false));
  const [ errorSteps, setErrorSteps ] = useState(STEPS.map(() => false));

  const setIsComplete = (index) => (value) => setCompleteSteps(
    setArrayValue(completeSteps, index, value)
  );

  const isComplete = (index) => completeSteps[index];

  const setError = (index) => (value) => setErrorSteps(
    setArrayValue(errorSteps, index, value)
  );

  const hasError = (index) => errorSteps[index];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Content = STEPS[activeStep].component ?? null

  return (
    <Box sx={{width: '100%'}}>
      <Stepper activeStep={activeStep}>
        {STEPS.map((step, index) => (
          <Step key={step.name} completed={activeStep == index ? undefined : isComplete(index)}>
            <StepLabel error={hasError(index)}>
              {step.name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {STEPS[activeStep].component ? (
        <Content
          wizard={wizardState}
          updateWizard={setWizardState}
          isComplete={isComplete(activeStep)}
          setIsComplete={setIsComplete(activeStep)}
          setError={setError(activeStep)}
        />
      ) : null}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Étape précédente
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep < STEPS.length - 1 ? (
          <Button
            onClick={handleNext}
            color="inherit"
            disabled={isComplete(activeStep)}
          >
            Passer l'étape
          </Button>
        ) : null}
        {activeStep <= STEPS.length - 1 ? (
          <Button onClick={handleNext} disabled={!isComplete(activeStep)}>
            {activeStep === STEPS.length - 1 ? 'Terminer' : 'Étape suivante'}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}
