import React, { useState, Fragment } from 'react';

import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PublishIcon from '@mui/icons-material/Publish';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import * as filetypes from '@src/filetypes';
import useApiClient from '@src/hooks/useApiClient';
import { uploadDisplayName } from '@src/helpers';


async function importExtent(api, { content }) {
  const configKey = 'INSTANCE_EXTENT';
  const existsResp = await api.fetch(`/configs/${configKey}`);
  const exists = (existsResp.status == 200);
  if (exists) {
    return await api.fetch(`/configs/${configKey}`, {
      method: 'PUT',
      body: JSON.stringify({
        value: content,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    return await api.fetch('/configs/', {
      method: 'POST',
      body: JSON.stringify({
        key: configKey,
        value: content,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}


async function importZones(api, { content }) {
  return api.fetch('/working-zones/', {
    method: 'POST',
    body: JSON.stringify(content),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}


async function checkDataImport(api, id) {
  const resp = await api.fetch(`/data-imports/${id}`);
  const res = await resp.json();
  return res
}

async function importTransportStop(api, upload) {
  const importType = {
    'NETEX': 'NETEX_TRANSPORT_STOPS',
    'OSM': 'OSM_TRANSPORT_STOPS',
  }[upload.type];
  const resp = await api.fetch('/data-imports/', {
    method: 'POST',
    body: JSON.stringify({
      type: importType,
      from_file_upload_id: upload.id,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const dataImport = await resp.json();
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const res = await checkDataImport(api, dataImport.id);
      if (res.status !== 'RUNNING') {
        resolve({ status: res.status == 'SUCCESS' ? 200 : 400 });
        clearInterval(interval);
      }
    }, 1000);
  });
}

async function importOsm(api, transportStops, poi, paths) {
  const args = {
    data_types: [
      transportStops?.osm ? 'transport' : false,
      poi?.osm ? 'poi' : false,
      paths?.osm ? 'path' : false,
    ].filter(val => val)
  };
  const resp = await api.fetch('/data-imports/', {
    method: 'POST',
    body: JSON.stringify({
      type: 'OSM',
      args,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const dataImport = await resp.json();
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const res = await checkDataImport(api, dataImport.id);
      if (res.status !== 'RUNNING') {
        resolve({ status: res.status == 'SUCCESS' ? 200 : 400 });
        clearInterval(interval);
      }
    }, 1000);
  });
}



async function importLicense(api, license, attribution) {
  const configKey = 'DATA_LICENSE';
  const existsResp = await api.fetch(`/configs/${configKey}`);
  const exists = (existsResp.status == 200);
  const value = {
    ...license,
    attribution,
  };
  if (exists) {
    return await api.fetch(`/configs/${configKey}`, {
      method: 'PUT',
      body: JSON.stringify({ value }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    return await api.fetch('/configs/', {
      method: 'POST',
      body: JSON.stringify({
        key: configKey,
        value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}


function ImportButton({
  importFunction,
  active=true,
}) {
  const [ running, setRunning ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ finished, setFinished ] = useState(false);
  
  const disabled = !active || running;
  const color = finished ? ( success ? 'success' : 'error') : null;

  const doRun = async () => {
    setRunning(true);
    const { status } = await importFunction();
    setRunning(false);
    setFinished(true);
    setSuccess(status < 300);
  }

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Fab disabled={disabled} onClick={doRun} color={color}>
        {finished ? (
          success ? <CheckIcon/> : <CrossIcon/>
        ) : <PublishIcon/>}
      </Fab>
      {running ? (
        <CircularProgress
          size={68}
          sx={{
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      ) : null}
    </Box>
  )
}

export default function Import({
  wizard={},
}) {

  const apiClient = useApiClient();
  
  const {
    extent,
    zones,
    transportStops={},
    poi={},
    paths={},
    license,
    attribution,
  } = wizard;
  
  const hasOsm = [transportStops, poi, paths].some(({ osm }) => !!osm);
  
  return (
    <List>
      <ListItem
        sx={{ pt: 4, pb: 4 }}
        secondaryAction={
          <ImportButton
            active={!!extent?.content}
            importFunction={() => importExtent(apiClient.current, extent)}
          />
        }
      >
        <ListItemText>
          Emprise
        </ListItemText>
      </ListItem>
      <Divider variant="middle"/>
      <ListItem
        sx={{ pt: 4, pb: 4 }}
        secondaryAction={
          <ImportButton
            active={!!zones?.content}
            importFunction={() => importZones(apiClient.current, zones)}/>
        }
      >
        <ListItemText>
          Zones de travail
        </ListItemText>
      </ListItem>
      <Divider variant="middle"/>
      <ListItem
        sx={{ pt: 4, pb: 4 }}
        secondaryAction={
          <ImportButton
            active={!!hasOsm}
            importFunction={() => importOsm(apiClient.current, transportStops, poi, paths)}/>
        }
      >
        <ListItemText>
          Donneés OpenStreetMap
        </ListItemText>
      </ListItem>
      <List sx={{ pl: 4}}>
        {transportStops?.osm ? (
          <Fragment>
            <ListItem>
              <ListItemText>
                Arrêts de transport
              </ListItemText>
            </ListItem>
          </Fragment>
        ) : null}
        {poi?.osm ? (
          <Fragment>
            <ListItem>
              <ListItemText>
                ERP
              </ListItemText>
            </ListItem>
          </Fragment>
        ) : null}
        {paths?.osm ? (
          <Fragment>
            <ListItem>
              <ListItemText>
                Voirie
              </ListItemText>
            </ListItem>
          </Fragment>
        ) : null}
      </List>
      <Divider variant="middle"/>
      <ListItem
        sx={{ pt: 4, pb: 4 }}
      >
        <ListItemText>
          Données NeTEx
        </ListItemText>
      </ListItem>
      <List sx={{ pl: 4}}>
        {transportStops?.uploads?.map((upload, index) => (
          <Fragment key={index}>
            <Divider variant="middle"/>
            <ListItem sx={{ pt: 4, pb: 4 }}
            secondaryAction={
              <ImportButton importFunction={() => importTransportStop(apiClient.current, upload)}/>
            }
            >
              <ListItemText>
                {filetypes.LABELS[upload.type]} - {uploadDisplayName(upload)}
              </ListItemText>
            </ListItem>
          </Fragment>
        ))}
      </List>
      <Divider variant="middle"/>
      <ListItem
        sx={{ pt: 4, pb: 4 }}
        secondaryAction={
          <ImportButton
            active={!!license}
            importFunction={() => importLicense(apiClient.current, license, attribution)}
          />
        }
      >
        <ListItemText>
          Licence
        </ListItemText>
      </ListItem>
    </List>
  );
}
