import React from 'react';

import centroid from '@turf/centroid';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LaunchIcon from '@mui/icons-material/Launch';

import staticWorkingZones from '@assets/data/working_zones.json';
import { EDITOR_URL, HIDE_CONFIG } from '@src/config';
import useCallApi from '@src/hooks/useCallApi';
import Page from '@src/components/Page';


const MAY_USE_STATIC_WORKING_ZONES = HIDE_CONFIG;

function EditorLink({ zone }) {
  const mapCenter = centroid(zone);
  const [lon, lat] = mapCenter.geometry.coordinates;
  const id = zone.properties?.id ?? 0;
  const name = zone.properties?.title || zone.properties?.name || `Zone ${id}`;
  return (
    <ListItem>
      <ListItemButton component="a" href={`${EDITOR_URL}#map=18/${lat}/${lon}`}>
        <ListItemIcon>
          <LaunchIcon/>
        </ListItemIcon>
        <ListItemText>
          {name}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default function WorkingZones() {
  const workingZones = useCallApi('/working-zones/');
  let features = workingZones?.features ?? [];
  if (!features.length && MAY_USE_STATIC_WORKING_ZONES) {
    features = staticWorkingZones.features;
  }
  return (
    <Page
      title={<Typography variant="h1">Zones de travail</Typography>}
    >
      <List>
        {features.map((feature) => (
          <EditorLink key={feature.properties.id} zone={feature}/>
        ))}
      </List>
    </Page>
  );
}
