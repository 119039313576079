import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Page from '@src/components/Page';
import * as styles from './MobileApp.module.css';
import { APK_INDEX_FILE_URL } from '@src/config';


async function getLastApkLink() {
  const url = APK_INDEX_FILE_URL;
  try {
    const response = await fetch(url);
    const { last } = await response.json();
    return last;
  } catch (err) {
    console.log(err);
  }
  return null;
}


export default function MobileApp() {
  const [ apkLink, setApkLink ] = useState(null);

  useEffect(() => {
    const execute = async () => {
      const link = await getLastApkLink();
      if (link) {
        setApkLink(link);
      }
    };
    execute();
  }, []);

  return (
    <Page
      title={<Typography variant="h1">Application mobile</Typography>}
    >
      <div className={styles.textContent}>
        <Typography variant="h2">
          Une application pour renseigner les attributs d'accessibilité sur le terrain.
        </Typography>
        <Typography>
          Une fois les cheminements et arrêts de transports dessinés au bureau sur la
          base des données SIG et ortho-photographies à votre disposition, l'application
          mobile <strong>Acceslibre Mobilités</strong> permet de se rendre sur le terrain pour relever
          sur place les attributs d'accessibilité indispensables.
        </Typography>
        <Typography>
          L'application est disponible pour les appareils Android.
        </Typography>
        <Typography>
          <Button variant="contained" component={Link} href={apkLink} disabled={!apkLink}>
            Télécharger la dernière version
          </Button>
        </Typography>
      </div>
    </Page>
  );
}

