{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": 1,
        "name": "Front de Lac (Évian-les-Bains)"
      },
      "geometry": {
        "coordinates": [
          [
            [
              6.5866063534865305,
              46.40230410102126
            ],
            [
              6.5866063534865305,
              46.40016715901186
            ],
            [
              6.5923857034101445,
              46.40016715901186
            ],
            [
              6.5923857034101445,
              46.40230410102126
            ],
            [
              6.5866063534865305,
              46.40230410102126
            ]
          ]
        ],
        "type": "Polygon"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 2,
        "name": "Mairie - ECLA (Lons-le-Saunier)"
      },
      "geometry": {
        "coordinates": [
          [
            [
              5.554730377434254,
              46.673942046816364
            ],
            [
              5.554730377434254,
              46.6706554261348
            ],
            [
              5.560919450443436,
              46.6706554261348
            ],
            [
              5.560919450443436,
              46.673942046816364
            ],
            [
              5.554730377434254,
              46.673942046816364
            ]
          ]
        ],
        "type": "Polygon"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": 3,
        "name": "Parc Chanot - Vélodrome (Marseille)"
      },
      "geometry": {
        "coordinates": [
          [
            [
              5.389803833802375,
              43.27425071192323
            ],
            [
              5.389803833802375,
              43.26647708414336
            ],
            [
              5.403389769326594,
              43.26647708414336
            ],
            [
              5.403389769326594,
              43.27425071192323
            ],
            [
              5.389803833802375,
              43.27425071192323
            ]
          ]
        ],
        "type": "Polygon"
      }
    }
  ]
}

