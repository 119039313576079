import React from 'react';

import * as styles from "./Page.module.css";

export default function Page({
  title=null,
  intro=null,
  children,
}) {
  return (
    <>
    <div className={styles.heading}>
      <div className={styles.title}>
        {title}
      </div>
    </div>
    <div className={styles.body}>
      {intro ? (
        <div className={styles.intro}>
          {intro}
        </div>
      ) : null}
      {children}
    </div>
    </>
  );
}
