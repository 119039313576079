import { BACKEND_URL } from '@src/config';


export default class ApiClient {

  constructor(userManager) {
    this.userManager = userManager;
    this.apiUrl = BACKEND_URL;
  }

  async getAccessToken() {
    const { access_token } = await this.userManager.getUser();
    return access_token;
  }

  async fetch(endpoint, opts={}) {
    const url = this.apiUrl + endpoint;
    const accessToken = await this.getAccessToken();
    const options = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return await fetch(url, options);
  }

}

