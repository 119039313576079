import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as filetypes from '@src/filetypes';
import { uploadDisplayName } from '@src/helpers';
import FileUploadForm from '@src/components/forms/FileUploadForm';
import DocLink from '@src/components/forms/DocLink';
import ExtentMap from '@src/components/forms/ExtentMap';



export default function PathsStep({
  wizard={},
  updateWizard,
  isComplete=false,
  setIsComplete,
  setError,
}) {

  const { paths={}, extent={} } = wizard;
  const { osm=false } = paths;

  const onChange = ({ target }) => {
    updateWizard({
      ...wizard,
      paths: {
        ...paths,
        osm: target.checked,
      },
    });
    setIsComplete(!!target.checked)
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        L'import des cheminements piétons permet d'initialiser le graphe piéton,
        c'est-à-dire le réseau des trottoirs et passages piétons qui constitue
        la base de la collecte d'information dans AccesLibre Mobilités.
      </Typography>
      <Typography>
        <DocLink path="/usage/imports/cheminement/">Voir la documentation en ligne</DocLink>
      </Typography>
      <Box sx={{ pt: 2}}>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={osm}
              onChange={onChange}
            />
          }
          label="Importer automatiquement les données OpenStreetMap de l'emprise"
          labelPlacement="end"
        />
      </Box>
      <ExtentMap extent={extent} defaultExpanded={true}/>
    </Box>
  );
}
