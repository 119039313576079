.summary {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 15px;
}


.Accordion:global(.Mui-disabled) {
  background-color: white !important;
  opacity: 1 !important;
}
